import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError, filter, Observable, of, tap } from 'rxjs';
import { ICpn } from 'src/app/repository/cpn/cpn.model';
import { CpnService } from 'src/app/repository/cpn/cpn.service';
import { IPage } from 'src/app/repository/page.model';
import { UserInfo } from 'src/app/repository/user-info/user-info.model';
import { AuthService } from '../../auth/auth.service';
import { LanguageService } from '../../core/services/language.service';
import { LoginComponent } from '../dialog/login/login.component';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements OnInit {
  lang: string;

  /** 로그인 여부 */
  isLoggedIn: boolean;

  /** 로그인 유저명 */
  userInfo$: Observable<UserInfo>;

  /** 쿠폰 목록 */
  cpnList$: Observable<IPage<ICpn>>;

  @Input() sideMenu: any;

  constructor(
    private authService: AuthService,
    private cpnService: CpnService,
    private router: Router,
    private matDialog: MatDialog,
    private languageService: LanguageService
  ) {
    this.userInfo$ = this.authService.loginSubject.asObservable().pipe(
      tap(() => {
        this.isLoggedIn =
          this.authService.loginInfo != null &&
          this.authService.loginInfo?.certType !== 'GUEST';

        if (this.isLoggedIn) {
          this.initCpnList();
        }
      })
    );
    this.userInfo$.subscribe();
  }

  ngOnInit(): void {
    this.setDefaultLang();
  }

  /** 쿠폰 목록 획득 */
  initCpnList(): void {
    this.cpnList$ = this.cpnService.list$;
    this.authService
      .getLoginInfo$()
      .pipe(
        tap((userInfo) => {
          this.cpnService.getPage({ userId: userInfo.id });
        }),
        // 미로그인 상태인경우
        catchError((e) => {
          return of(e);
        })
      )
      .subscribe();
  }

  /**
   * 브라우저 언어 값 확인 후 초기 표시 언어 설정
   */
  setDefaultLang(): void {
    const defaultLang = this.languageService.currentLocale.value;
    this.lang = defaultLang;
  }

  onChangeLanguage(lang: string): void {
    this.languageService.changeLang(lang);
  }

  /**
   * 로그아웃 버튼 클릭 시 무조건 로그아웃 처리. login과 동작이 다른 이유는 logout 버튼을 어떻게 표시할지 정책에 따라 달라질 수 있으므로
   *
   * ex> 다른 요소로서 로그아웃 버튼을 만들면 logout 메소드를 바인딩하고, 같은 요소를 활용하면 상태값에 따라 login 메소드로 구분 동작
   */
  onClickLogout(): void {
    // this.keyCloakService.logout();

    // this.oidcSecurityService.logoff();
    this.close();
    this.authService.logout();
    setTimeout(() => {
      window.location.href = '/';
    }, 500);
  }

  close(): void {
    this.sideMenu.toggle();
  }

  /**
   * 로그인 버튼 클릭 시 로그인 상태에 따라 로그인페이지로 이동하거나, 로그아웃 처리
   */
  onClickLogin(): void {
    this.close();
    this.matDialog
      .open(LoginComponent)
      .beforeClosed()
      .pipe(
        filter((isLogin) => isLogin),
        tap(() => {
          this.userInfo$.subscribe().unsubscribe();
        })
      )
      .subscribe();
  }
}
