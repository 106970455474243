import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

export const BRAND_CD = new InjectionToken<string>('brandCd');
export const BRAND_ID = new InjectionToken<string>('brandId');

@Injectable()
export class BrandInterceptor implements HttpInterceptor {
  constructor(
    @Inject(BRAND_CD)
    private brandCd: string,
    @Inject(BRAND_ID)
    private brandId: string
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.startsWith(environment.apiServerUrl) &&
      !req.url.includes('/pms/')
    ) {
      let { headers } = req;
      let { params } = req;
      if (this.brandCd) {
        params = req.params.set('brandCd', this.brandCd);
      }
      if (this.brandId != null) {
        headers = req.headers.set('Brand-Id', `${this.brandId}`);
        // params = req.params.set('brandId', this.brandId);
      }
      return next.handle(
        req.clone({
          // API 서버 CorsFilter에서 Access-Control-Allow-Headers에 Brand-Id 추가 필요
          headers,
          params,
        })
      );
    }
    return next.handle(req);
  }
}
