import * as _ from 'lodash-es';

import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PwLocalePipe } from 'pw-lib';
import { Subscription } from 'rxjs';
import { LanguageService } from '../core/services/language.service';

@Pipe({
  name: 'locale',
  pure: false,
})
export class LocalePipe implements PipeTransform, OnDestroy {
  onLangChange: Subscription;

  onDefaultLangChange: Subscription;

  constructor(
    private translate: TranslateService,
    private cDR: ChangeDetectorRef
  ) {}

  transform(
    value: any,
    key?: string,
    lang = this.translate.currentLang
  ): string {
    if (!this.onLangChange) {
      this.onLangChange = this.translate.onLangChange.subscribe(() => {
        this.cDR.markForCheck();
      });
    }

    if (!this.onDefaultLangChange) {
      this.onDefaultLangChange = this.translate.onDefaultLangChange.subscribe(
        () => {
          this.cDR.markForCheck();
        }
      );
    }

    if (typeof value === 'string') {
      return new PwLocalePipe().transform(value, lang);
    }
    if (value) {
      const suffixKey = lang === LanguageService.FALLBACK_LANG ? '' : lang;
      return value[`${key}${_.startCase(suffixKey)}`];
    }
    return null;
  }

  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
    this.onDefaultLangChange?.unsubscribe();
  }
}
