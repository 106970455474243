import { Component, Inject, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AlertDialogButtonOptions {
  text?: string;
  color?: ThemePalette;
  value?: any;
}

export interface AlertDialogData {
  title?: string;
  message?: string;
  positiveButton?: AlertDialogButtonOptions;
  negativeButton?: AlertDialogButtonOptions;
  neutralButton?: AlertDialogButtonOptions;
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: AlertDialogData
  ) {
    if (!data) {
      throw Error('data == null');
    }
  }

  ngOnInit(): void {}

  onClickPositive(): void {
    this.dialogRef.close(this.data.positiveButton?.value ?? true);
  }

  onClickNegative(): void {
    this.dialogRef.close(this.data.negativeButton?.value ?? false);
  }

  onClickNeutral(): void {
    this.dialogRef.close(this.data.neutralButton?.value ?? null);
  }
}
