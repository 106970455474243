<div class="tab-spacer" fxHide="true" fxShow.lt-sm="true"></div>
<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="tab-group"
  fxHide="true"
  fxShow.lt-sm="true"
>
  <a
    class="tab"
    fxLayout="column"
    fxLayoutGap="6px"
    fxLayoutAlign="center center"
    routerLink="/"
    role="link"
    matRipple
  >
    <img class="tab-icon" src="/assets/imgs/tab/home-icon.png" />
    <span class="tab-title font-caption">{{ 'home' | translate }}</span>
  </a>
  <a
    class="tab"
    fxLayout="column"
    fxLayoutGap="6px"
    fxLayoutAlign="center center"
    routerLink="/rsrv/facility"
    role="link"
    matRipple
  >
    <img class="tab-icon" src="/assets/imgs/tab/rsrv-icon.png" />
    <span class="tab-title font-caption">{{ 'rsrv' | translate }}</span>
  </a>
  <a
    class="tab"
    fxLayout="column"
    fxLayoutGap="6px"
    fxLayoutAlign="center center"
    routerLink="/my/fav"
    role="link"
    matRipple
  >
    <img class="tab-icon" src="/assets/imgs/tab/favorite-icon.png" />
    <span class="tab-title font-caption">{{ 'fav' | translate }}</span>
  </a>
  <a
    class="tab"
    fxLayout="column"
    fxLayoutGap="6px"
    fxLayoutAlign="center center"
    routerLink="/my/rsrv"
    role="link"
    matRipple
  >
    <img class="tab-icon" src="/assets/imgs/tab/rsrv-list-icon.png" />
    <span class="tab-title font-caption">{{ 'rsrvHist' | translate }}</span>
  </a>
  <a
    class="tab"
    fxLayout="column"
    fxLayoutGap="6px"
    fxLayoutAlign="center center"
    routerLink="/mobile-my"
    role="link"
    matRipple
  >
    <img class="tab-icon" src="/assets/imgs/tab/my-icon.png" />
    <span class="tab-title font-caption">MY</span>
  </a>
</div>
