import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { BehaviorSubject, map, Observable, shareReplay } from 'rxjs';

export interface ScrollEvent {
  scrollTop?: number;
  scrollLeft?: number;
  scrollHeight?: number;
  scrollWidth?: number;
}

/**
 * UI 제어를 위한 클래스
 */
@Injectable({
  providedIn: 'root',
})
export class UiService {
  /**
   * app-root의 스크롤 이벤트 서브젝트
   */
  scrollEvent$: BehaviorSubject<ScrollEvent> = new BehaviorSubject({});

  /** 모바일 구분해서 표시할 때 사용 */
  isMobile$: Observable<boolean>;

  device$: Observable<string>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private mediaObserver: MediaObserver
  ) {
    // 모바일 기기 기준
    this.isMobile$ = this.breakpointObserver
      .observe(['(max-width: 600px)'])
      .pipe(
        map((r: any) => {
          return r.matches;
        }),
        shareReplay()
      );
    this.device$ = this.mediaObserver.asObservable().pipe(
      map(() => {
        if (this.mediaObserver.isActive('lg')) {
          return 'l';
        }
        if (this.mediaObserver.isActive('md')) {
          return 'm';
        }
        if (
          this.mediaObserver.isActive('sm') ||
          this.mediaObserver.isActive('xs')
        ) {
          return 's';
        }
        return 'pc';
      })
    );
  }
}
