import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { LoginComponent } from './login/login.component';
import { TmConfirmBookingComponent } from './tm-confirm-booking/tm-confirm-booking.component';

const componentList = [
  AlertDialogComponent,
  TmConfirmBookingComponent,
  LoginComponent,
];

@NgModule({
  declarations: componentList,
  imports: [
    CommonModule,
    MatDialogModule,
    SharedModule,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatRippleModule,
    ReactiveFormsModule,
    TranslateModule,
    PipesModule,
  ],
  exports: [...componentList, MatDialogModule],
})
export class DialogModule {}
