<div class="popup">
  <span class="header">{{ data?.header }}</span>
  <img
    class="close-btn"
    role="button"
    (click)="onNo()"
    src="/assets/imgs/close-button-icon-blue.png"
  />

  <div class="body">
    <!-- <div class="input-box">
      <label for="bookingId">{{ 'bookingId' | translate }}</label>
      <input id="bookingId" [(ngModel)]="bookingId" />
    </div> -->
    <div class="input-box">
      <label for="confirmKey">{{ 'confirmKey' | translate }}</label>
      <input id="confirmKey" [(ngModel)]="confirmKey" />
    </div>
  </div>
</div>

<div class="popUp_btn">
  <div class="yes_btn" (click)="onYes()">{{ 'confirm' | translate }}</div>
  <div class="no_btn" (click)="onNo()">{{ 'cancel' | translate }}</div>
</div>
