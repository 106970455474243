import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tournity-layout',
  templateUrl: './tournity-layout.component.html',
  styleUrls: ['./tournity-layout.component.scss'],
})
export class TournityLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
