import { Component, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { UserInfo } from 'src/app/repository/user-info/user-info.model';
import { AuthService } from '../../auth/auth.service';
import { TournityHeaderComponent } from '../tournity-header/tournity-header.component';

/**
 * @deprecated {@link TournityHeaderComponent}에 통합
 */
@Component({
  selector: 'app-tournity-menu',
  templateUrl: './tournity-menu.component.html',
  styleUrls: ['./tournity-menu.component.scss'],
})
export class TournityMenuComponent implements OnInit {
  /** 로그인 여부 */
  isLoggedIn: boolean;

  userInfo$: Observable<UserInfo>;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.userInfo$ = this.authService.loginSubject.asObservable().pipe(
      tap(() => {
        this.isLoggedIn =
          this.authService.loginInfo != null &&
          this.authService.loginInfo?.certType !== 'GUEST';
      })
    );
    this.userInfo$.subscribe().unsubscribe();
  }

  onClickRefreshInfo(): void {
    this.authService.refreshLoginInfo$().subscribe();
  }
}
