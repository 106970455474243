import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';
import { PW_SUPPORTED_LOCALES } from 'pw-lib';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  static readonly FALLBACK_LANG = 'ko';

  /**
   * 현재 선택한 언어
   */
  #lang: string;

  /**
   * 현재 선택한 언어가 없다면 스토리지 > 브라우저 기본 값 중 지원 언어, en 순으로 확인하며 가져온다
   */
  get lang(): string {
    if (this.#lang) {
      return this.#lang;
    }

    this.#lang =
      localStorage.getItem('lang') ??
      (navigator.language || LanguageService.FALLBACK_LANG);

    if (!this.isSupportedLang(this.#lang)) {
      this.#lang = LanguageService.FALLBACK_LANG;
    }

    this.changeLang(this.#lang);

    return this.#lang;
  }

  get currentLocale(): { name: string; value: string } {
    return this.SUPPORTED_LANGUAGES.find((item) => item.value === this.lang);
  }

  constructor(
    @Inject(PW_SUPPORTED_LOCALES)
    public SUPPORTED_LANGUAGES: { name: string; value: string }[],
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private authService: AuthService
  ) {}

  /** 표시 언어 상태를 변경하고 스토리지에 저장 */
  changeLang(lang: string): void {
    if (!lang) {
      this.changeLang(LanguageService.FALLBACK_LANG);
      return;
    }

    if (!this.isSupportedLang(lang)) {
      this.changeLang(LanguageService.FALLBACK_LANG);
      console.error('target language not supported');
      return;
    }

    this.#lang = lang;
    this.translateService.use(lang);
    this.document.documentElement.lang = lang;

    localStorage.setItem('lang', lang);
  }

  getSupportedLanguageByBrand(): {
    name: string;
    value: string;
    suffixKey: string;
  }[] {
    const brandFnJson = JSON.parse(
      this.authService.loginInfo.brand.brandFnTypeJson ?? '{}'
    );
    const brandSupportedLang = Object.entries(brandFnJson)
      .filter(([key, value]) => {
        return key.startsWith('LANG_') && value;
      })
      .map(([key]) => key);

    return this.SUPPORTED_LANGUAGES.filter((lang) => {
      return brandSupportedLang.includes(`LANG_${lang.value.toUpperCase()}`);
    }).map((lang) => {
      return {
        ...lang,
        suffixKey:
          lang.value === LanguageService.FALLBACK_LANG
            ? ''
            : _.startCase(lang.value),
      };
    });
  }

  private isSupportedLang(lang: string): boolean {
    return !!this.SUPPORTED_LANGUAGES.find((item) => item.value === lang);
  }
}
