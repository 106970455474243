<div>
  <div class="btn-group" fxLayout="row" fxLayoutAlign="flex-end center">
    <select
      tabindex="-1"
      class="language-select"
      [pwCoachMark]="{ text: 'GUIDE.changeLang' | translate }"
      [(ngModel)]="lang"
      (ngModelChange)="onChangeLanguage($event)"
    >
      <option value="ja">日本語</option>
      <option value="ko">한국어</option>
      <option value="en">English</option>
    </select>

    <button
      mat-menu-item
      type="button"
      (click)="onClickLogout()"
      id="logout"
      *ngIf="isLoggedIn"
    >
      <img src="/assets/imgs/logout-icon.png" />
    </button>

    <button (click)="close()" id="close" type="button">
      <img src="/assets/imgs/close-button-icon-square.png" />
    </button>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="flex-start center"
    class="profile"
    *ngIf="isLoggedIn; else guest"
  >
    <ng-container *ngIf="userInfo$ | async as userInfo">
      <span
        class="profile-circle"
        fxLayout="row"
        fxLayoutAlign="center center"
      ></span>
      <div>
        <p>{{ 'welcome' | translate }}</p>
        <p>
          <ng-container *ngIf="lang === 'ja' || lang === 'ko'; else en">
            {{ userInfo.familyName }}
            {{ userInfo.firstName }}
          </ng-container>
          <ng-template #en>
            {{ userInfo.familyName }}
            {{ userInfo.firstName }}
          </ng-template>
          {{ 'sir' | translate }}
        </p>
      </div>
    </ng-container>
  </div>
  <ng-template #guest>
    <div
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      fxLayoutGap="20px"
      class="profile guest"
    >
      <button
        role="link"
        matRipple
        type="button"
        routerLink="/join"
        (click)="close()"
      >
        {{ 'join' | translate }}
      </button>
      <button role="link" matRipple type="button" (click)="onClickLogin()">
        {{ 'login' | translate }}
      </button>
    </div>
  </ng-template>
  <div
    class="membership"
    fxLayout="row"
    fxLayoutAlign="flex-start center"
    (click)="close()"
    routerLink="/tabi"
    *ngIf="isLoggedIn"
  >
    <img src="/assets/imgs/info-icon-black.png" />
    <p>{{ 'membershipIntro' | translate }}</p>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-around center" class="my-menu">
    <a routerLink="/my/rsrv" (click)="close()" role="link" matRipple>{{
      'rsrvHist' | translate
    }}</a>
    <img class="divider-bar" src="/assets/imgs/divider-bar.svg" />
    <a routerLink="/my/fav" (click)="close()" role="link" matRipple>{{
      'fav' | translate
    }}</a>
  </div>

  <div
    class="cpn-wrapper"
    *ngIf="cpnList$ | async as cpnList"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    routerLink="/my/cpn"
    (click)="close()"
  >
    <span class="cpn-title">{{ 'cpn' | translate }}</span>
    <span class="cpn-cnt" *ngIf="isLoggedIn"
      >{{ cpnList.content.length ?? 0 | number }}
      {{ 'UNIT.cnt' | translate }}</span
    >
  </div>

  <div class="menu-list">
    <a
      class="menu-item"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      routerLink="/home"
      (click)="close()"
      role="link"
      matRipple
    >
      HOME
    </a>
    <!-- <a
      class="menu-item"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      routerLink="/rsrv/facility"
      (click)="close()"
      role="link"
      matRipple
    >
      {{ 'rsrv' | translate }}
    </a> -->
    <!-- <a
      class="menu-item"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      routerLink="/ordr"
      (click)="close()"
      role="link"
      matRipple
    >
      {{ 'ordr' | translate }}
    </a> -->
    <!-- <a
      class="menu-item child"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      routerLink="/ordr/hist"
      (click)="close()"
      role="link"
      matRipple
    >
      {{ 'delngHist' | translate }}
    </a> -->
    <a
      class="menu-item"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      routerLink="/facility"
      (click)="close()"
      role="link"
      matRipple
    >
      {{ 'facilityList' | translate }}
    </a>
    <a
      class="menu-item"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      routerLink="/video"
      (click)="close()"
      role="link"
      matRipple
    >
      {{ 'introMv' | translate }}
    </a>
    <!-- <a
      class="menu-item parent"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
    >
      {{ 'csCenter' | translate }}
    </a> -->
    <!-- <a
      class="menu-item child"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      routerLink="/cs/notice"
      (click)="close()"
      role="link"
      matRipple
    >
      {{ 'notice' | translate }}
    </a> -->
    <!-- <a
      class="menu-item child"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      routerLink="/cs/qna"
      (click)="close()"
      role="link"
      matRipple
    >
      {{ 'qnaPrivate' | translate }}
    </a> -->
    <a
      class="menu-item"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      routerLink="/company"
      (click)="close()"
      role="link"
      matRipple
    >
      {{ 'companyInfo' | translate }}
    </a>
    <a
      class="menu-item"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      routerLink="/tabi"
      (click)="close()"
      role="link"
      matRipple
    >
      {{ 'tabiClub' | translate }}
    </a>
    <a
      class="menu-item"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      routerLink="/recruit"
      (click)="close()"
      role="link"
      matRipple
    >
      {{ 'recruit' | translate }}
    </a>
  </div>
</div>
