import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { DrawerModule } from '../drawer/drawer.module';
import { TournityFooterModule } from '../tournity-footer/tournity-footer.module';
import { TournityHeaderModule } from '../tournity-header/tournity-header.module';
import { TournityMenuModule } from '../tournity-menu/tournity-menu.module';
import { TournityTabBarModule } from '../tournity-tab-bar/tournity-tab-bar.module';
import { TournityLayoutComponent } from './tournity-layout.component';

@NgModule({
  declarations: [TournityLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    TournityHeaderModule,
    TournityFooterModule,
    TournityMenuModule,
    TournityTabBarModule,
    MatSidenavModule,
    DrawerModule,
  ],
})
export class TournityLayoutModule {}
