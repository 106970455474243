import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { TournityTabBarComponent } from './tournity-tab-bar.component';

@NgModule({
  declarations: [TournityTabBarComponent],
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [TournityTabBarComponent],
})
export class TournityTabBarModule {}
