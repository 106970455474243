import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-tm-confirm-booking',
  templateUrl: './tm-confirm-booking.component.html',
  styleUrls: ['./tm-confirm-booking.component.scss'],
})
export class TmConfirmBookingComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TmConfirmBookingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  bookingId: string;

  confirmKey: string;

  ngOnInit(): void {}

  onYes(): void {
    this.dialogRef.close({
      bookingId: this.bookingId,
      confirmKey: this.confirmKey,
    });
  }

  onNo(): void {
    this.dialogRef.close(false);
  }
}
