import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { camelCase } from 'lodash-es';
import { Observable } from 'rxjs';
import {
  AlertDialogComponent,
  AlertDialogData,
} from './alert-dialog/alert-dialog.component';

const matDialogConfig: MatDialogConfig<any> = {
  hasBackdrop: true,
  backdropClass: 'transparent',
};

/**
 * 머터리얼 컴포넌트를 이용한 대화창 서비스
 * @see `DialogModule`
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    public matDialog: MatDialog,
    private translateService: TranslateService
  ) {}

  /**
   * AlertDialog 창
   * @param config 설정
   */
  open(config?: MatDialogConfig<AlertDialogData>): Observable<any> {
    const dialogRef = this.matDialog.open(AlertDialogComponent, {
      ...matDialogConfig,
      data: <AlertDialogData>{},
      ...config,
    });
    return dialogRef.afterClosed();
  }

  /**
   * 확인 창
   * @param title 본문 내용
   * @param message 상단 내용
   * @param config data 제외한 설정 내용
   */
  alert(
    message: string,
    title?: string,
    config?: MatDialogConfig<AlertDialogData>
  ): Observable<any> {
    return this.open({
      data: { title, message, positiveButton: {} },
      ...config,
    });
  }

  /**
   * 예/아니오 창
   * @param title 상단 내용
   * @param message 본문 내용
   * @param config data 제외한 설정 내용
   */
  confirm(
    message: string,
    title?: string,
    config?: MatDialogConfig<AlertDialogData>
  ): Observable<any> {
    return this.open({
      data: { title, message, positiveButton: {}, negativeButton: {} },
      ...config,
    });
  }

  /**
   * 오류 표시 창
   * @param body 본문 내용
   * @param header 상단 내용
   * @param config data 제외한 설정 내용
   */
  openErrorAlert(
    error: string,
    message?: string,
    config?: MatDialogConfig<void>
  ): Observable<any> {
    return this.open({
      data: {
        message: `${this.translateService.instant(
          camelCase(error)
        )}\n${message}`,
      },
      ...config,
    });
  }

  /**
   * 사용자 지정 팝업 오픈
   * @param component 표시할 컴포넌트
   * @param config 설정
   */
  openComponent(
    component: ComponentType<any>,
    config?: MatDialogConfig<any>
  ): Observable<any> {
    return this.matDialog
      .open(component, {
        ...matDialogConfig,
        panelClass: 'no-shadow-panel',
        ...config,
      })
      .afterClosed();
  }

  /**
   * Opens a snackbar with a message and an optional action.
   * @param message The message to show in the snackbar.
   * @param action The label for the snackbar action.
   * @param config Additional configuration options for the snackbar.
   */
  // snackbar(
  //   message: string,
  //   action: string = null,
  //   config: MatSnackBarConfig = { duration: 2000 }
  // ): void {
  //   this._snackBar.open(message, action, config);
  // }
}
