import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { TournityLayoutComponent } from './components/tournity-layout/tournity-layout.component';

const routes: Routes = [
  {
    path: 'landing',
    loadChildren: () =>
      import('./components/landing/landing.module').then(
        (m) => m.LandingModule
      ),
  },
  {
    path: '',
    component: TournityLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'unauthorized',
        loadChildren: () =>
          import('./components/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./components/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'my',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/my/my.module').then((m) => m.MyModule),
      },
      {
        path: 'cs',
        loadChildren: () =>
          import('./components/cs/cs.module').then((m) => m.CsModule),
      },
      {
        path: 'chrg',
        loadChildren: () =>
          import('./components/chrg/chrg.module').then((m) => m.ChrgModule),
      },
      {
        path: 'rsrv',
        loadChildren: () =>
          import('./components/rsrv/rsrv.module').then((m) => m.RsrvModule),
      },
      {
        path: 'find',
        loadChildren: () =>
          import('./components/find/find.module').then((m) => m.FindModule),
      },
      {
        path: 'facility',
        loadChildren: () =>
          import('./components/facility/facility.module').then(
            (m) => m.FacilityModule
          ),
      },
      {
        path: 'package/:id',
        loadChildren: () =>
          import('./components/package-detail/package-detail.module').then(
            (m) => m.PackageDetailModule
          ),
      },
      {
        path: 'join',
        loadChildren: () =>
          import('./components/join/join.module').then((m) => m.JoinModule),
      },
      {
        path: 'mobile-my',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/mobile-my/mobile-my.module').then(
            (m) => m.MobileMyModule
          ),
      },
      {
        path: 'ordr',
        loadChildren: () =>
          import('./components/ordr/ordr.module').then((m) => m.OrdrModule),
      },
      {
        path: 'tabi',
        loadChildren: () =>
          import('./components/tabi/tabi.module').then((m) => m.TabiModule),
      },
      {
        path: 'company',
        loadChildren: () =>
          import('./components/company/company.module').then(
            (m) => m.CompanyModule
          ),
      },
      {
        path: 'recruit',
        loadChildren: () =>
          import('./components/recruit/recruit.module').then(
            (m) => m.RecruitModule
          ),
      },
      {
        path: 'video',
        loadChildren: () =>
          import('./components/intro-video/intro-video.module').then(
            (m) => m.IntroVideoModule
          ),
      },
      // /신규 프로젝트 구조에 맞춘 경로 목록

      {
        path: 'login',
        loadChildren: () =>
          import('./components/login/login.module').then((m) => m.LoginModule),
      },
      { path: '', pathMatch: 'full', redirectTo: '/home' },
      { path: '**', redirectTo: '/home' },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
