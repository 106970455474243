import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeZh from '@angular/common/locales/zh';
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatDialogConfig,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import {
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import {
  AuthInterceptor,
  PW_STORAGE_PREFIX,
  PW_SUPPORTED_LOCALES,
} from 'pw-lib';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth/auth.service';
import { DialogModule } from './components/dialog/dialog.module';
import { InputModule } from './components/input/input.module';
import { TournityLayoutModule } from './components/tournity-layout/tournity-layout.module';
import { maskConfig } from './core/mask-config';
import {
  PwUpdateOption,
  PW_UPDATE_OPTION,
} from './core/services/app-update.service';
import { LanguageService } from './core/services/language.service';
import { TranslateVersionLoader } from './core/translate-version-loader';
import { PipesModule } from './pipes/pipes.module';
import {
  BrandInterceptor,
  BRAND_CD,
  BRAND_ID,
} from './repository/brand.interceptor';

registerLocaleData(localeKo);
registerLocaleData(localeJa);
registerLocaleData(localeZh);

const pwUpdateOption: PwUpdateOption = {
  messageTranslateKey: 'updateMessage',
  actionTranslateKey: 'updateAction',
};

@NgModule({
  declarations: [
    AppComponent,
    // HomeComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      // enabled: environment.production,
      enabled: false,
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (httpClient: HttpClient) =>
          new TranslateVersionLoader(httpClient),
        deps: [HttpClient],
      },
      // 선택한 언어의 번역 값이 적절하게 없을 때.
      /* missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: PwMissingTranslationHandler,
      }, */
    }),
    NgxMaskModule.forRoot(maskConfig),
    DialogModule,
    PipesModule,
    InputModule,
    FlexLayoutModule,
    MatIconModule,
    TournityLayoutModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSnackBarModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useFactory: (authService: AuthService) => {
        return new AuthInterceptor(
          authService,
          `${environment.apiServerUrl}/api`
        );
      },
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: BrandInterceptor,
      deps: [BRAND_CD, BRAND_ID],
    },
    { provide: BRAND_CD, useValue: '0' },
    { provide: BRAND_ID, useValue: 1 },
    { provide: COMPOSITION_BUFFER_MODE, useValue: false },
    { provide: PW_STORAGE_PREFIX, useValue: 'TOURNITY' },
    { provide: 'showPwCoachMarkCloseBtn', useValue: false },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'JPY' },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (lang: LanguageService) => {
        return lang.lang;
      },
      deps: [LanguageService],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: <MatFormFieldDefaultOptions>{ appearance: 'standard' },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: <MatDialogConfig>{ ...new MatDialogConfig(), autoFocus: false },
    },
    {
      provide: PW_SUPPORTED_LOCALES,
      useValue: [
        { name: '한국어', value: 'ko' },
        { name: '日本語', value: 'ja' },
        { name: 'English', value: 'en' },
      ],
    },
    { provide: PW_UPDATE_OPTION, useValue: pwUpdateOption },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
