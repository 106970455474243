import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocalePipe } from './locale.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeResourceUrlPipe } from './safe-resource-url.pipe';
import { TourTermPipe } from './tour-term.pipe';

@NgModule({
  declarations: [LocalePipe, SafeHtmlPipe, SafeResourceUrlPipe, TourTermPipe],
  imports: [CommonModule],
  exports: [LocalePipe, SafeHtmlPipe, SafeResourceUrlPipe, TourTermPipe],
})
export class PipesModule {}
