/* eslint-disable camelcase */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { StorageService } from '../services/storage.service';

/**
 * 페이위드 토큰 인터페이스
 */
export interface PwToken {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
}

type UserInfo = {
  id: number;
  userId: string;
  userCard: any;
  [key: string]: any;
};

type MrhstTrmnl = {
  id: number;
  userId: string;
  mrhst: any;
  [key: string]: any;
};

type Admin = {
  id: number;
  adminId: string;
  roles: string[];
  brand: any;
  [key: string]: any;
};

export type LoginInfo = UserInfo | MrhstTrmnl | Admin;

/**
 * 인증 서비스 규격화
 *
 * 액세스 토큰 / 갱신 토큰 / 만료일시
 * 를 인증에 사용하는 것을 전제로 공통화 했고, 추가적으로 필요한 변수나 기능은 상속받은데에서 구현
 *
 * 예> 아이디, 암호, 헤더 정보, 정보 스토리지에 저장 등
 */
export abstract class AbstractAuthService<T = PwToken> {
  /**
   * 인증 정보.
   *
   * Token, Bearer, 유효시간 등을 포함한다
   */
  auth: T;

  /**
   * 액세스 토큰. API 요청에 사용
   */
  accessToken: string;

  /**
   * 갱신 토큰. 액세스토큰 만료 후 갱신토큰이 있다면 refresh()를 통해 accessToken을 갱신한다
   */
  refreshToken: string;

  /**
   * 액세스 토큰의 만료일시
   */
  expireDttm: Date;

  /**
   * 로그인 아이디
   */
  userName: string;

  /**
   * 로그인 비밀번호
   */
  password: string;

  get loginInfo(): LoginInfo {
    return this.storageService.get(`${this.prefix}.loginInfo`);
  }

  set loginInfo(info: LoginInfo) {
    if (info) {
      this.storageService.set(`${this.prefix}.loginInfo`, info);
    } else {
      this.storageService.delete(`${this.prefix}.loginInfo`);
    }
  }

  protected abstract extFixedParams: any;

  /**
   * 로그인 정보 취득
   */
  abstract getLoginInfo$(id?: string): Observable<LoginInfo>;

  abstract logout(): void;

  constructor(
    protected httpClient: HttpClient,
    protected storageService: StorageService,
    protected authUrl: string,
    protected prefix: string,
    protected client: string
  ) {
    this.initAuth();
  }

  /**
   * 로그인
   */
  login(userName: string, password: string): Observable<LoginInfo> {
    this.clearAuth();
    this.userName = userName;
    this.password = password;
    return this.getLoginInfo$(userName);
  }

  /**
   * 새 인증정보 요청
   */
  getNewAuth(): Observable<T> {
    if (!this.userName) {
      return of(null);
    }

    // 요청 헤더 설정
    const headers = {
      Authorization: `Basic ${btoa(this.client)}`,
    };

    // 요청 Param 설정
    const params = {
      grant_type: 'password',
      username: this.userName,
      password: this.password,
      ...this.extFixedParams,
    };

    // 인증정보 요청 서버 통신부분
    return this.httpClient
      .post<T>(this.authUrl, null, {
        headers,
        params,
      })
      .pipe(
        tap((token) => this.setAuth(token)),
        catchError((httpErrorResponse: HttpErrorResponse) => {
          return throwError(() => httpErrorResponse);
        })
      );
  }

  /**
   * 리프래시 토큰을 이용하여 엑세스토큰 갱신
   */
  getRefreshAuth(): Observable<T> {
    const headers = {
      Authorization: `Basic ${btoa(this.client)}`,
    };

    const params = {
      grant_type: 'refresh_token',
      refresh_token: this.refreshToken,
    };

    return this.httpClient
      .post<T>(this.authUrl, null, {
        headers,
        params,
      })
      .pipe(
        tap((token) => this.setAuth(token)),
        catchError((httpErrorResponse: HttpErrorResponse) => {
          this.logout();
          return throwError(() => httpErrorResponse);
        })
      );
  }

  /**
   * 인증정보 삭제
   */
  clearAuth(): void {
    delete this.auth;
    delete this.accessToken;
    delete this.refreshToken;
    delete this.expireDttm;

    // 로그인 정보 초기화
    this.userName = null;
    this.password = null;
    this.storageService.delete(`${this.prefix}.loginInfo`);

    // 브라우저 인증정보 삭제
    this.storageService.delete(`${this.prefix}.expireDttm`);
    this.storageService.delete(`${this.prefix}.accessToken`);
    this.storageService.delete(`${this.prefix}.refreshToken`);
    this.storageService.delete(`${this.prefix}.auth`);
  }

  /**
   * 인증정보 초기화
   */
  protected initAuth(): void {
    // string -> Date 변환
    this.expireDttm = new Date(
      this.storageService.get(`${this.prefix}.expireDttm`)
    );
    this.accessToken = this.storageService.get(`${this.prefix}.accessToken`);
    this.refreshToken = this.storageService.get(`${this.prefix}.refreshToken`);
    this.auth = this.storageService.get(`${this.prefix}.auth`);
  }

  /**
   * 인증정보 저장
   */
  protected setAuth(res: T | any): void {
    // 클래스 변수에 인증정보 저장
    this.expireDttm = new Date(Date.now() + res.expires_in * 1000);
    this.accessToken = res.access_token;
    this.refreshToken = res.refresh_token;
    this.auth = res;

    // 브라우저에 토큰정보 저장
    this.storageService.set(`${this.prefix}.expireDttm`, this.expireDttm);
    this.storageService.set(`${this.prefix}.accessToken`, this.accessToken);
    this.storageService.set(`${this.prefix}.refreshToken`, this.refreshToken);
    this.storageService.set(`${this.prefix}.auth`, this.auth);
  }
}
