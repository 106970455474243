import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'tourTerm',
})
export class TourTermPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string, ...args: any[]): string {
    const newValue = value as string;

    if (typeof newValue !== 'string') {
      return this.translate.instant('Free Period');
    }

    if (+newValue.split('N')[1].split('D')[0] < 1) {
      return this.translate.instant('Free Period');
    }

    return newValue
      .replace('N', this.translate.instant('FORM.N'))
      .replace('D', this.translate.instant('FORM.D'));
  }
}
