<mat-drawer-container hasBackdrop="true">
  <mat-drawer #sideMenu mode="over" position="end">
    <app-drawer [sideMenu]="sideMenu" fixedInViewport="true"></app-drawer>
  </mat-drawer>
  <app-tournity-header [sideMenu]="sideMenu"></app-tournity-header>

  <!-- <app-tournity-menu></app-tournity-menu> -->

  <router-outlet></router-outlet>

  <app-tournity-footer></app-tournity-footer>

  <app-tournity-tab-bar></app-tournity-tab-bar>
</mat-drawer-container>
