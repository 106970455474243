import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AppService, PwCoachMarkService } from 'pw-lib';
import { DialogService } from './components/dialog/dialog.service';
import { AppUpdateService } from './core/services/app-update.service';
import { RouteHelperService } from './core/services/route-helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  UPDATE_MSG = '새 버전으로 업데이트 합니다';

  highlightLink = false;

  constructor(
    public routeHelperService: RouteHelperService,
    private _router: Router,
    private _swUpdate: SwUpdate,
    private _appService: AppService,
    private dialogService: DialogService,
    private pwCoachMarkService: PwCoachMarkService,
    private appUpdateService: AppUpdateService
  ) {}

  ngOnInit(): void {
    this.updateAppStyle();
    this.appUpdateService.init();
  }

  /**
   * 앱 화면 전체를 사용할 수 있도록 설정하고, 상태바 아이콘을 밝게 설정한다
   */
  updateAppStyle(): void {
    // this._statusBarService.setStatusBarBrightness('dark');
    // SafeArea 비활성화
    // this._appService.useSafeArea(true);
    // 기본 뒤로가기 처리 비활성화
    this._appService.useDefaultBackHandler(false);
    // 루트라면 앱 종료, 아니면 뒤로가기
    this._appService.addBackPressed(() => {
      // 열려있는 다이얼로그가 있다면 마지막 요소 닫기
      const { openDialogs } = this.dialogService.matDialog;
      if (openDialogs?.length) {
        const lastDialog = openDialogs.pop();
        lastDialog.close();
        return;
      }

      if (
        this.routeHelperService.getCurrentActivatedRoute().snapshot.data
          .type === 'root'
      ) {
        if (!this._router.url.includes('home')) {
          this._router.navigate(['home']);
          return;
        }
        this._appService.exit();
      } else {
        if (
          this.routeHelperService.getCurrentActivatedRoute().snapshot.data
            .handleBack
        ) {
          return;
        }
        this.routeHelperService.goBack();
      }
    });
  }

  toggleLinkHighlight() {
    this.pwCoachMarkService.toggleCoachMark();
  }
}
