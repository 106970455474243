<h2
  mat-dialog-title
  *ngIf="data.title"
  [innerHTML]="data.title | translate | safeHtml"
></h2>
<mat-dialog-content
  [innerHTML]="data.message ?? '' | translate | safeHtml"
></mat-dialog-content>
<mat-dialog-actions>
  <button
    *ngIf="data.neutralButton"
    mat-button
    [color]="data.neutralButton.color ?? 'primary'"
    (click)="onClickNeutral()"
  >
    {{ data.neutralButton.text ?? 'BUTTON.Close' | translate }}
  </button>
  <div class="space"></div>
  <button
    *ngIf="data.negativeButton"
    mat-button
    [color]="data.negativeButton.color ?? 'primary'"
    (click)="onClickNegative()"
  >
    {{ data.negativeButton.text ?? 'BUTTON.Cancel' | translate }}
  </button>
  <button
    *ngIf="data.positiveButton"
    mat-button
    [color]="data.positiveButton.color ?? 'primary'"
    (click)="onClickPositive()"
  >
    {{ data.positiveButton.text ?? 'BUTTON.Ok' | translate }}
  </button>
</mat-dialog-actions>
