import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tournity-footer',
  templateUrl: './tournity-footer.component.html',
  styleUrls: ['./tournity-footer.component.scss'],
})
export class TournityFooterComponent implements OnInit {
  @Input()
  topScrollBehavior: 'auto' | 'smooth' = 'smooth';

  constructor(
    @Inject(DOCUMENT)
    private document: Document
  ) {}

  ngOnInit(): void {}

  onClickTop(): void {
    // <html> 요소 scroll 변경
    this.document.documentElement.scrollTo({
      top: 0,
      behavior: this.topScrollBehavior,
    });
  }
}
