<footer class="footer-wrap" ngClass.lt-sm="mobile">
  <div class="footer">
    <div class="row">
      <table class="menu-table">
        <thead>
          <tr>
            <th>주식회사 캐어니티</th>
            <th>문의 및 주소</th>
            <th>정보</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>대표이사</td>
            <td>00123456789</td>
            <td><a>개인정보</a></td>
          </tr>
          <tr>
            <td>대표</td>
            <td>접수 시간 10:00 - 15:00 (토·일·휴일 제외)</td>
            <td><a>특정 상거래</a></td>
          </tr>
          <tr>
            <td>여행업무 통합관리 솔루션</td>
            <td>어디시 어디어디 어디 어디 123-123</td>
            <td><a>이용약관</a></td>
          </tr>
          <tr>
            <td></td>
            <td>사업자등록번호 110-00-00000</td>
            <td><a>환불정책</a></td>
          </tr>
        </tbody>
      </table>
      <div class="top">
        <button class="btn" type="button" (click)="onClickTop()">
          <svg class="icon" width="46" height="46">
            <use href="/assets/imgs/ic_top.svg#path"></use>
          </svg>
          <span class="label">TOP</span>
        </button>
      </div>
    </div>
    <div class="row">
      <a class="logo" routerLink="/home">
        <img src="/assets/imgs/ic_logo.svg" />
      </a>
    </div>
  </div>
</footer>
