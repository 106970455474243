<div
  class="toolbar-spacer"
  ngClass.lt-sm="mobile"
  [fxShow.lt-sm]="showHeader"
></div>
<div class="toolbar-wrapper" ngClass.lt-sm="mobile" [fxShow.lt-sm]="showHeader">
  <mat-toolbar ngClass.lt-sm="mobile">
    <a class="logo img-btn" routerLink="/home" fxHide.lt-sm="true">
      <img
        class="company-logo"
        src="/assets/imgs/ic_logo.svg"
        ngClass.lt-sm="mobile"
      />
    </a>

    <a
      class="logo img-btn"
      routerLink="/home"
      *ngIf="!headerTitle; else hasTitle"
      fxShow.lt-sm="true"
      fxHide="true"
    >
      <img
        class="company-logo"
        src="/assets/imgs/ic_logo.svg"
        ngClass.lt-sm="mobile"
      />
    </a>
    <ng-template #hasTitle>
      <div
        class="header-title-wrapper"
        fxShow.lt-sm="true"
        fxHide="true"
        fxLayout
        fxLayoutAlign="flex-start center"
      >
        <img
          class="go-back-icon"
          role="button"
          matRipple
          (click)="onClickBack()"
          src="/assets/imgs/arrow-back-icon-white.png"
        />
        <span class="header-title"> {{ headerTitle | translate }}</span>
      </div>
    </ng-template>
    <div class="spacer half"></div>
    <nav class="nav-list" fxHide.lt-sm>
      <ul>
        <li>
          <a routerLink="/home" routerLinkActive="on">HOME</a>
        </li>
        <li>
          <a
            routerLink="/facility"
            routerLinkActive="on"
            (mouseenter)="isPackageHover = true"
            (mouseleave)="isPackageHover = false"
            >PACKAGE
            <div class="submenu-wrap" *ngIf="isPackageHover">
              <menu class="submenu">
                <li
                  routerLink="/facility"
                  [queryParams]="{ mrhstSubTypeList: 'golfcourse' }"
                  (click)="
                    isPackageHover = false;
                    $event.stopPropagation();
                    $event.preventDefault()
                  "
                >
                  {{ 'golfcourse' | translate }}
                </li>
                <li
                  routerLink="/facility"
                  [queryParams]="{ mrhstSubTypeList: 'ryokan' }"
                  (click)="
                    isPackageHover = false;
                    $event.stopPropagation();
                    $event.preventDefault()
                  "
                >
                  {{ 'ryokan' | translate }}
                </li>
              </menu>
            </div>
          </a>
        </li>
        <li>
          <a routerLink="/cs" routerLinkActive="on">NEWS</a>
        </li>
        <li>
          <a routerLink="/my" routerLinkActive="on">MYPAGE</a>
        </li>
      </ul>
    </nav>
    <div class="spacer"></div>
    <ng-container *ngIf="!isLoggedIn">
      <button mat-button (click)="onClickLogin()">
        {{ 'login' | translate }}
      </button>
      <button mat-button routerLink="/join">
        {{ 'join' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="isLoggedIn">
      <div class="user-info" *ngIf="userInfo$ | async as userInfo">
        <span class="name"
          >{{ userInfo.userNm ?? userInfo.userId }}
          {{ 'sir' | translate }}</span
        >
        <!-- <span class="point">{{ userInfo.userCard?.prpayAmt | number }}P</span>
        <button class="refresh" mat-icon-button (click)="onClickRefreshInfo()">
          <mat-icon fontSet="material-symbols-rounded">refresh</mat-icon>
        </button> -->
      </div>
      <button mat-button (click)="onClickChangeInfo()">
        {{ 'changeUserInfo' | translate }}
      </button>
      <button mat-button (click)="onClickLogout()">
        {{ 'logout' | translate }}
      </button>
    </ng-container>
    <button
      mat-icon-button
      [pwCoachMark]="{ text: 'GUIDE.changeLang' | translate }"
      fxHide.lt-sm="true"
      class="language"
      [matMenuTriggerFor]="language"
    >
      <svg width="36" height="36" viewBox="0 0 24 24">
        <use href="/assets/imgs/ic_locale.svg#path" />
      </svg>
    </button>
    <mat-menu #language="matMenu">
      <button mat-menu-item (click)="onChangeLanguage('ja')">
        <span>日本語</span>
      </button>
      <button mat-menu-item (click)="onChangeLanguage('ko')">
        <span>한국어</span>
      </button>
      <button mat-menu-item (click)="onChangeLanguage('en')">
        <span>English</span>
      </button>
    </mat-menu>
    <button
      class="side-menu"
      (click)="sideMenu.toggle()"
      fxHide
      fxShow.lt-sm="true"
      *ngIf="!headerTitle"
    >
      <img src="/assets/imgs/menu-icon.png" />
    </button>
  </mat-toolbar>
</div>
